export function getToken(){
    return localStorage.getItem('Authorization');
}

export function removeToken(){
    return localStorage.removeItem('Authorization');
}

export function setToken(token){
    return localStorage.setItem('Authorization',token);
}

// 商户登录信息
export function setTenant(tenant){
    return localStorage.setItem('tenant',JSON.stringify(tenant));
}

export function getTenant(){
    return JSON.parse(localStorage.getItem('tenant')) 
}

export function reomveTenant(){
    return localStorage.removeItem('tenant');
}
