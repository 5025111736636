import axios from 'axios'
import { Toast } from 'vant'
import { getToken, removeToken, reomveTenant } from '@/tool/cookie'
import { json2Form, isFunction } from '@/tool/common'
import { getWXCode } from '@/tool/login'
import errTypeCallback from './error-list'

const baseURL = process.env.VUE_APP_API_URL
const request = axios.create({
  baseURL,
  timeout: 30000,
  method: 'post'
})

request.interceptors.request.use(
  config => {
    getToken() ? (config.headers.Authorization = `Bearer ${getToken()}`) : ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    const status = response.status
    if (status === 200) {
      return response.data
    } else {
      return Promise.reject(new Error(response.data || '请求失败'))
    }
  },
  error => {
    const { response, message } = error
    const { businessErrorType } = response.data

    if (response.status === 401) {
      Toast('用户授权失效, 正在重新登录')
      reomveTenant()
      removeToken()
      getWXCode(
        `${window.location.origin}/login${json2Form({
          targetUrl: encodeURIComponent(window.location.href)
        })}`
      )
    } else if (response.status === 400) {
      const cb = errTypeCallback[businessErrorType]

      if (isFunction(errTypeCallback[businessErrorType])) {
        cb(error.response)
      } else {
        Toast(cb || message || '接口请求错误')
      }
    } else if (response.status === 500) {
      Toast('服务器错误')
    }
    return Promise.reject(response.data)
  }
)

export default request
