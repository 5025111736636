import request from '@/tool/axios'

// 获取微信授权地址
export function authorizedUrl(data) {
    return request({
        url: '/wechat/huizhi/getauthorizeuri',
        data
    })
}

// 微信配置信息 wx.config
export function weChatConfig(params,headers) {
    return request({
        url: '/wechat/huizhi/getjssdkpackage',
        method:'get',
        params,
        headers
    })
}