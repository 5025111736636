import { authorizedUrl } from '@/api/wechat'

/**
 * 跳转微信获取授权 code
 * @param {String}  url     回调地址
 * @param {Object=} options 额外透传参数
 * */
export const getWXCode = async (url, options) => {
  const res = await authorizedUrl({
    redirectUrl: encodeURIComponent(url),
    scope: 'snsapi_base',
    state: '',
    ...options
  })
  window.location.href = decodeURIComponent(res)
}
